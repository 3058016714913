<template>
  <div
    :class="[
      'h-full min-h-screen w-full',
      { 'bg-white': layoutConfig.color === 'white' },
    ]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const { layoutConfig } = useLayoutConfig()
</script>
